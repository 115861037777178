<template>
    <div>
        <div>
            <b-row>
                <b-col sm="4">
                    <Select title="Categoria (Padrão) " url="api/v1/finance/chart-of-accounts/select-all"
                        :showNewRegister="false" v-model="chartOfAccounts">
                    </Select>
                </b-col>
                <b-col sm="4">
                    <Select title="Tipo de Pagamento (Padrão)" url="/api/v1/finance/type-payment/select-all"
                        :showNewRegister="false" v-model="typePayment">
                    </Select>
                </b-col>
                <b-col sm="4">
                    <Select title="Conta Bancária (Padrão)" url="api/v1/finance/bank-account/select-all"
                        :showNewRegister="false" v-model="bankAccount">
                    </Select>
                </b-col>
            </b-row>
            <br>
        </div>
        <div class="main-reconciliation">
            <div v-for="item in selected">
                <div class="div-molded">
                    <Molded>
                        <ItemReconciliation :item="item" :isNew="true" :removed="removeItem" />
                    </Molded>
                </div>
            </div>
            <div v-show="selected.length == 0">
                <span>Nenhum registro selecionado!</span>
            </div>
        </div>
        <div class="div-btn text-center">
            <Button _key="btnCreatePayableReceivable" :disabled="selected.length == 0" type="success"
                title="Gerar Conciliação" size="medium" :clicked="createPayableReceivable" />
        </div>
    </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import ItemReconciliation from './ItemReconciliation.vue'

import { mapActions, mapMutations } from "vuex";

export default {
    name: "ReconciliationList",
    props: {
        selected: Array,
        value: Number
    },
    components: {
        Molded, Button, ItemReconciliation, Select
    },
    data() {
        return {
            urlCreatePayableReceivable: "/api/v1/finance/bank-reconciliation/create-payable-receivable",
            chartOfAccounts: {},
            typePayment: {},
            bankAccount: {},
        }
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
        createPayableReceivable() {
            let params = {
                url: this.urlCreatePayableReceivable,
                obj:
                    { id: this.id, listBankReconciliationResponse: this.selected },
                notNotifyToast: true,
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.hideModal();
                    this.addEvent({ name: "reconciliationAddExecuted" });
                }
                this.removeLoading(["btnCreatePayableReceivable"]);

            });
        },
        removeItem(id) {
            let filter = this.selected.filter(function (item) {
                return item.id != id;
            });
            this.selected = filter;
        },
    },
    watch: {
        chartOfAccounts: {
            handler(chartOfAccounts) {
                this.selected.forEach(item => {
                    item.chartOfAccounts = { id: chartOfAccounts.id, content: chartOfAccounts.content }
                });
            },
            deep: true,
        },
        typePayment: {
            handler(typePayment) {
                this.selected.forEach(item => {
                    item.typePayment = { id: typePayment.id, content: typePayment.content }
                });
            },
            deep: true,
        },
        bankAccount: {
            handler(bankAccount) {
                this.selected.forEach(item => {
                    item.bankAccount = { id: bankAccount.id, content: bankAccount.content }
                });
            },
            deep: true,
        },
    },

}
</script>
<style scoped>
.main-reconciliation {
    height: 500px;
    overflow: auto;
    padding-right: 10px;
}

.div-molded {
    margin-bottom: 25px;
}

.div-btn {
    margin: 10px
}
</style>