<template>
    <div>
        <div class="div-title">
            <span class="title">
                <i class="fa-regular fa-hand-holding-dollar"></i>
                Sugestões para conciliação
            </span>
        </div>
        <Molded>
            <div v-if="type == 0">
                <i class="fa-sharp fa-solid fa-triangle-exclamation"></i> Selecione uma transação ao lado para verificar
                as sugestões!
            </div>
            <div v-else>
                <Loading type="line" :center="false" v-show="loading" />
                <div class="div-filter">
                    <b-row>
                        <b-col sm="12">
                            <div class="text-right">
                                <Button title="Filtro" classIcon="fa-solid fa-filter" size="small" type="info"
                                    :clicked="openModalFilter" />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <ItemFixedBar :selected="selected" :showBtnReconcile="true" />
                <ScrollBar :minHeight="300" :maxHeight="600">
                    <table class="table table-responsive-xs">
                        <thead>
                            <tr>
                                <th>
                                    <b-form-checkbox v-model="showSelectAll" @change="selectAll"
                                        v-show="transactions.length > 0">
                                    </b-form-checkbox>
                                </th>
                                <th>
                                    <span class="title-header">Descrição</span>
                                </th>
                                <th class="text-center">
                                    <span class="title-header">Vcto/Valor</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="item in transactions">
                            <tr :class="{ pay: item.type == 1, receive: item.type == 2 }">
                                <td class="td-checkbox">
                                    <b-form-checkbox v-model="selected" :value="item.id"
                                        @change="selectComplete(item)" />
                                </td>
                                <td class="td-description">
                                    <i v-if="item.value == value" class="fa-solid fa-hand-holding-dollar"></i>
                                    <span class="title"> {{ item.recipientName }}</span><br>
                                    <span class="title-description title"> {{ item.description }}</span>
                                </td>
                                <td class="text-center title">
                                    <span> {{ item.dueDate }}</span><br>
                                    {{ item.value | currency }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-show="transactions.length == 0">
                            <tr>
                                <td colspan="12">
                                    <span>Nenhuma sugestão encontrada!</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ScrollBar>
            </div>
        </Molded>
        <Modal title="Conciliação" :width="1200" :height="750" v-if="showModal('confirmReconcile')"
            :onHideModal="onHideModal">
            <ReconciliationList :selected="selectedAll" :id="id" :value="value" />
        </Modal>
        <Modal title="Filtro" :width="700" :height="750" v-if="showModal('filter')">
            <b-row>
                <b-col sm="6">
                    <DateTime title="Vencimento" fieldTarget="dueDate" format="DD/MM/YYYY" type="date" :range="true"
                        :confirm="true" confirmText="Filtrar" v-model="period" />
                </b-col>
                <b-col sm="3">
                    <InputDecimal title="Valor Inicial" :required="false" :markFormDirty="false" :maxLength="5"
                        type="float" v-model="valueStart" />
                </b-col>
                <b-col sm="3">
                    <InputDecimal title="Valor Final" :required="false" :markFormDirty="false" :maxLength="5"
                        type="float" v-model="valueEnd" />
                </b-col>
            </b-row>
            <div class="text-center">
                <Button title="Aplicar" classIcon="fa-solid fa-filter" size="small" type="primary"
                    :clicked="applyFilter" />
            </div>
        </Modal>
    </div>
</template>
<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";

import ReconciliationList from './ReconciliationList.vue'
import ItemFixedBar from './ItemFixedBar.vue'

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    name: "TransactionsList",
    components: { Molded, ScrollBar, Loading, ItemFixedBar, Modal, DateTime, Button, InputDecimal, ReconciliationList },
    data() {
        return {
            loading: false,
            urlGetApi: "/api/v1/finance/bank-reconciliation/get-all-suggestion",
            transactions: [],
            selectedAll: [],
            selected: [],
            showSelectAll: false,
            id: "",
            type: 0,
            dueDate: "",
            value: 0,
            period: [],
            valueStart: 0,
            valueEnd: 0
        };
    },
    computed: {
        ...mapState("generic", ["event"]),
        ...mapGetters("generic", ["showModal"]),
    },
    methods: {
        ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["getApi", "deleteAllApi"]),
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGetApi,
                obj:
                {
                    type: this.type,
                    dateStart: this.period[0],
                    dateEnd: this.period[1],
                    valueStart: this.valueStart,
                    valueEnd: this.valueEnd
                }
            };
            this.getApi(params).then((response) => {
                this.transactions = response.content;
                this.loading = false;
            });
        },
        selectAll() {
            this.selected = [];
            this.selectedAll = [];
            if (this.showSelectAll) {
                for (let i in this.transactions) {
                    this.selected.push(this.transactions[i].id);
                    this.selectedAll.push(this.transactions[i]);
                }
            }
        },
        selectComplete(item) {
            this.selectedAll.push(item);
        },
        openModalFilter() {
            this.openModal("filter");

        },
        applyFilter() {
            this.getAll();
            this.hideModal();
        },
        onHideModal() {
            this.selected = [];
            this.selectedAll = [];
            this.showSelectAll = false;
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "transactionSelected") {
                    this.id = event.data.id;
                    this.type = event.data.type;
                    this.dueDate = event.data.dueDate;
                    this.value = event.data.value;

                    this.valueStart = Math.abs(event.data.value * 0.8); // 20% less than value
                    this.valueEnd = Math.abs(event.data.value * 1.2);

                    this.period = [];

                    var newDateStart = new Date(event.data._DueDate);
                    newDateStart.setDate(newDateStart.getDate() - 10);
                    var formattedDateStart = newDateStart.toLocaleDateString('pt-BR', { minimumIntegerDigits: 2, day: '2-digit', month: '2-digit', year: 'numeric' });
                    this.period.push(formattedDateStart);

                    var newDateEnd = new Date(event.data._DueDate);
                    newDateEnd.setDate(newDateEnd.getDate() + 10);
                    var formattedDateEnd = newDateEnd.toLocaleDateString('pt-BR', { minimumIntegerDigits: 2, day: '2-digit', month: '2-digit', year: 'numeric' });
                    this.period.push(formattedDateEnd);


                    this.selected = [];
                    this.getAll();
                }

                if (event.name == "fileLoaded") {
                    this.type = 0;
                    this.selected = [];
                    this.transactions = [];
                }

                if (event.name == "reconcileItemFixedBar") {
                    this.openModal("confirmReconcile");
                    this.removeLoading(["btnReconcileItem"]);
                }

                if (event.name == "reconciliationExecuted") {
                    this.selected = [];
                    this.selectedAll = [];
                    this.showSelectAll = false;
                    this.getAll();
                }

            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}


.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-description {
    width: 400px;
}


.pay {
    color: red;
}

.receive {
    color: darkblue;
}

.check {
    background-color: #FFFBE6;
}

.div-filter {
    margin-bottom: 10px;
}
</style>